<template>
  <div>
    <p class="display-1 mt-2 mb-5 text-uppercase">
      {{ $t('title-container-release-order-search') }}
    </p>

    <div class="">
      <v-card
        class="py-1 px-2"
        rounded="lg"
      >
        <v-card-text>
          <v-form
            @submit="onSearch"
            onSubmit="return false;"
          >
            <v-row align="center">
              <BaseTextField
                v-model="searchNo"
                clearable
                :label="$t('label-cro-search')"
                type="text"
                prepend-inner-icon="mdi-google-nearby"
                solo
                hide-details
                item-text="name"
                item-value="code"
                :loading="isLoading"
                :readonly="isLoading"
                @click:clear="onClearSearch"
              />
              <v-btn
                class="px-10"
                large
                :class="{
                  'mt-2': $vuetify.breakpoint.mobile,
                }"
                :width="$vuetify.breakpoint.mobile ? '100%' : undefined"
                :disabled="isLoading"
                color="primary"
                @click="onSearch"
              >
                <v-icon> mdi-magnify </v-icon>
                {{ $t('search') }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-scroll-y-transition>
        <ContainerReleaseOrderSearchResult
          v-if="render && containerReleaseOrderList.length > 0"
          class="mt-4 mb-16"
          :loading="isLoading"
          :search-no="searchNo"
        />
      </v-scroll-y-transition>
    </div>
  </div>
</template>

<script>
import BaseTextField from '@/components/shared/local/base/BaseTextField.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import ContainerReleaseOrderSearchResult from './ContainerReleaseOrderSearchResult.vue';
export default {
  components: { ContainerReleaseOrderSearchResult, BaseTextField },
  data: () => ({
    isLoading: false,
    searchNo: '',
    dialog: {
      result: false,
    },
    render: false,
  }),
  computed: {
    ...mapState('vsl/containerReleaseOrder', ['containerReleaseOrderList']),
  },
  created() {
    const { search_no } = this.$route.query;
    this.searchNo = search_no || '';
  },
  destroyed() {
    this.RESET();
  },
  methods: {
    ...mapMutations('vsl/containerReleaseOrder', ['RESET']),
    ...mapActions('vsl/containerReleaseOrder', ['searchContainerReleaseOrder']),
    async onSearch() {
      if (!this.searchNo || this.searchNo.length === 0) {
        await this.$router.push('').catch((e) => e);
        return;
      }
      try {
        this.isLoading = true;
        this.RESET(['containerReleaseOrderList']);
        await this.$router.push(`?search_no=${this.searchNo}`).catch((e) => e);
        await this.searchContainerReleaseOrder({
          searchNo: this.searchNo,
        });
        this.render = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
        this.render = true;
        if (this.$vuetify.breakpoint.mobile) {
          this.dialog.result = true;
        }
        console.log(this.containerReleaseOrderList);
      } finally {
        this.isLoading = false;
      }
    },
    async onClearSearch() {
      await this.$router.push('').catch((e) => e);
    },
  },
};
</script>

<style></style>
