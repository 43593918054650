<template>
  <div>
    <div>Kết quả tìm kiếm</div>
    <ContainerReleaseOrderSearchResultList />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ContainerReleaseOrderSearchResultList from './ContainerReleaseOrderSearchResultList.vue';
export default {
  components: { ContainerReleaseOrderSearchResultList },
  data: () => ({}),
  computed: {
    ...mapState('vsl/containerReleaseOrder', ['containerReleaseOrderList']),
  },
  created() {},
  destroyed() {},
  methods: {
    ...mapMutations('vsl/containerReleaseOrder', ['RESET']),
    ...mapActions('vsl/containerReleaseOrder', ['searchContainerReleaseOrder']),
  },
};
</script>

<style></style>
